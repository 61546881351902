import '#/src/assets/scss/main.scss'
import {Navbar} from "#/src/components/navbar/Navbar";
import {Footer} from "#/src/components/Footer";

export {Layout}

function Layout({children}) {
  return <>
    <Navbar/>
    {children}
    <Footer/>
  </>
}