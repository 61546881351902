import {LiveStreamersHeader} from "#/src/components/LiveStreamersHeader";
import {Content} from "#/src/pages/index/Content";

export {Page}

function Page() {
  return <>
    <LiveStreamersHeader/>
    <Content/>
  </>
}
