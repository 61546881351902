import AppConstants from "#/src/utils/app-constants";
import {composeHeaders} from "#/src/utils/api-request-utils";
import {Attachments} from "#/src/utils/attachment-storage";
import {getProfileUrl} from "#/src/utils/profile-url";
import {TimeDisplay} from "#/src/components/TimeDisplay";
import {ApiShoutboxMessage, ApiUserPermission} from "#/src/definitions/ApiDefinitions";
import check from "#/src/assets/svg/check.svg";
import xClose from "#/src/assets/svg/x-close.svg";
import React from "react";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext} from "#/src/definitions/AppDefinitions";
import DOMPurify from "isomorphic-dompurify";
import {truncateString} from "#/src/utils/string-utils";
import styled from "@emotion/styled";
import {AvatarWithFallback} from "#/src/components/AvatarWithFallback";

const AuthorAnchor = styled.a`
  color: #ffc700 !important;
`;

const PendingModeration = styled.small`
  color: hsla(0, 0%, 100%, .75);
`;

interface Props {
  shoutboxMessage: ApiShoutboxMessage,
  onRefetch: () => void
}

export function ShoutboxMessage({shoutboxMessage, onRefetch}: Props) {
  const pageContext = usePageContext() as AppPageContext;
  const {user} = pageContext;

  async function onAccept() {
    const response = await fetch(`${AppConstants.API_ROUTE}/home/shoutbox/${shoutboxMessage.id}`, {
      method: "PATCH",
      headers: composeHeaders(pageContext),
      body: JSON.stringify({pendingModeration: false})
    });
    if (response.ok) {
      await onRefetch();
    }
  }

  async function onDelete() {
    const response = await fetch(`${AppConstants.API_ROUTE}/home/shoutbox/${shoutboxMessage.id}`, {
      method: "DELETE",
      headers: composeHeaders(pageContext)
    });
    if (response.ok) {
      await onRefetch();
    }
  }

  function render() {
    function parseUrls(message) {
      const urlPattern = /(\b(https?:\/\/|www\.|[-A-Z0-9]+\.[A-Z]{2,})[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

      return message.replace(urlPattern, (url) => {
        if (!/^https?:\/\//i.test(url)) {
          url = 'http://' + url;
        }
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${truncateString(url, 45)}</a>`;
      });
    }

    return <div className="shoutbox-item">
      <div className="msg">
        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(parseUrls(shoutboxMessage.message))}}/>
      </div>
      {renderPending()}
      <div className="author">
        <AvatarWithFallback src={Attachments.getUserAvatar(shoutboxMessage.author)} alt={shoutboxMessage.author.username} className={"avatar avatar-20 me-1"}/>
        <AuthorAnchor href={getProfileUrl(shoutboxMessage.author)} className={"fw-bold"}>{shoutboxMessage.author.username}</AuthorAnchor>
        <span className={"ms-1"}><TimeDisplay date={shoutboxMessage.creationDate}/></span>
      </div>
    </div>
  }

  function renderPending() {
    if (!shoutboxMessage.pendingModeration) {
      return <></>
    }
    if (user.data?.permission != ApiUserPermission.MODERATOR
      && user.data?.permission != ApiUserPermission.ADMINISTRATOR
      && user.data?.permission != ApiUserPermission.SUPER_ADMINISTRATOR) {
      return <div className={"shoutbox-control justify-content-center"}>
        <PendingModeration className={"p-1"}>Wiadomość w moderacji</PendingModeration>
      </div>
    }
    return <div className={"shoutbox-control"}>
      <button type={"button"} onClick={onAccept}>
        <img src={check} alt={"Akceptuj"}/> Zaakceptuj
      </button>
      <button type={"button"} onClick={onDelete}>
        <img src={xClose} alt={"Usuń"}/> Usuń
      </button>
      <button/>
    </div>
  }

  return render();
}