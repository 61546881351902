import {ApiArticle} from "#/src/definitions/ApiDefinitions";
import {Link} from "#/src/components/Link";
import {truncateTitle} from "#/src/utils/string-utils";
import {AttachmentNamespace, Attachments} from "#/src/utils/attachment-storage";
import {ClickableWrapper} from "#/src/components/ClickableWrapper";
import {navigate} from "vike/client/router";

interface Props {
  article: ApiArticle,
  lazy?: boolean
}

export function ShortArticleCard({article, lazy = false}: Props) {
  return <div className="col-xl-4 col-md-6">
    <ClickableWrapper onClick={() => navigate(`/blog/${article.slug}`)}>
      <article className="article-short">
        <figure className="short-img">
          <img loading={lazy ? "lazy" : "eager"} src={`${Attachments.getUrl(AttachmentNamespace.ARTICLE, article)}-small`} alt={article.slug}/>
        </figure>
        <div className="short-content">
          <a href="#" className="tag-small">{article.category.name}</a>
          <Link href={`/blog/${article.slug}`} className="short-title">{truncateTitle(article.title)}</Link>
        </div>
      </article>
    </ClickableWrapper>
  </div>
}