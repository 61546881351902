import dotsVertical from "#/src/assets/svg/dots-vertical.svg";
import React, {useState} from "react";
import send from "#/src/assets/svg/send.svg";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext, DataWrapped} from "#/src/definitions/AppDefinitions";
import {useData} from "vike-react/useData";
import {ApiHomeData, ApiShoutboxMessage} from "#/src/definitions/ApiDefinitions";
import {Sort} from "#/src/utils/sort-utils";
import AppConstants from "#/src/utils/app-constants";
import Swal from "sweetalert2/dist/sweetalert2.js";
import withReactContent from "sweetalert2-react-content";
import {composeHeaders} from "#/src/utils/api-request-utils";
import {toast} from "react-toastify";
import {ShoutboxMessage} from "#/src/pages/index/ShoutboxMessage";
import styled from "@emotion/styled";
import {useInterval} from "#/src/hooks/use-interval";

const SwalText = styled.div`
  white-space: pre-wrap;
`;

const Container = styled.div`
  position: relative;
`;

const Disclaimer = styled.div`
  font-size: .75em;
  position: absolute;
  top: -20px;
  width: 100%;
  background-color: #24242e;
  color: #eef5f8;
  text-align: center;
  padding: .25em;
`;

const Anchor = styled.span`
  color: #ef1828;
  cursor: pointer;
`;

export function Shoutbox() {
  const pageContext = usePageContext() as AppPageContext;
  const {user} = pageContext;
  const {data: homeData} = useData() as DataWrapped<ApiHomeData>;
  const [messages, setMessages] = useState<ApiShoutboxMessage[]>(homeData.shoutboxMessages);
  const [disclaimer, setDisclaimer] = useState(false);
  useInterval(onRefetch, 1000 * 60);

  async function onRefetch() {
    const response = await fetch(`${AppConstants.API_ROUTE}/home/shoutbox`, {method: "GET", headers: composeHeaders(pageContext)});
    if (response.ok) {
      setMessages(await response.json());
    }
  }

  async function onSend() {
    if (!user.loggedIn) {
      return;
    }
    const messageBox = (document.getElementById("shoutbox-message") as HTMLInputElement);
    const message = messageBox.value;
    const response = await fetch(`${AppConstants.API_ROUTE}/home/shoutbox`, {
      method: "POST",
      headers: composeHeaders(pageContext),
      body: JSON.stringify({message})
    });
    setDisclaimer(false);
    if (response.ok) {
      messageBox.value = "";
      await onRefetch();
    } else {
      const errorResponse = await response.json() as any;
      if (errorResponse.errors) {
        errorResponse.errors.forEach(err => toast(err));
      }
    }
  }

  function onRulesOpen() {
    withReactContent(Swal).fire({
      title: <div className={"mb-4"}>Regulamin shotubox</div>,
      html: <SwalText>{homeData.shoutboxRules}</SwalText>,
      padding: "2em",
      confirmButtonText: "Rozumiem",
      customClass: {
        confirmButton: "swal-confirm"
      },
      showClass: {
        popup: ""
      },
      hideClass: {
        popup: ""
      },
    });
  }

  function onChatRulesOpen() {
    withReactContent(Swal).fire({
      title: <div className={"mb-4"}>Zasady chatu</div>,
      html: <SwalText>{homeData.shoutboxChatRules}</SwalText>,
      padding: "2em",
      confirmButtonText: "Rozumiem",
      customClass: {
        confirmButton: "swal-confirm"
      },
      showClass: {
        popup: ""
      },
      hideClass: {
        popup: ""
      },
    });
  }

  function onEnterCheck(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      onSend();
    }
  }

  function render() {
    return <div className="shoutbox">
      <div className="shoutbox-settings">
        <div className="dropdown">
          <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <img src={dotsVertical} alt="#"/>
          </button>
          <ul className="dropdown-menu dropdown-menu-end">
            <li>
              <div className="dropdown-item cursor-pointer" onClick={() => onRulesOpen()}>Regulamin</div>
            </li>
            <li>
              <div className="dropdown-item cursor-pointer" onClick={() => onChatRulesOpen()}>Zasady chatu</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="shoutbox-content">{renderMessages()}</div>
      {renderInput()}
    </div>
  }

  function renderMessages() {
    return Sort.byDate(messages).map((shoutboxMessage: ApiShoutboxMessage) => <ShoutboxMessage key={shoutboxMessage.id} shoutboxMessage={shoutboxMessage}
                                                                                               onRefetch={onRefetch}/>);
  }

  function renderInput() {
    if (!user.loggedIn) {
      return <div className={"input-shoutbox"}>
        <input type="text" className="form-shoutbox" placeholder="Zaloguj się by wysłać wiadomość..." id="shoutbox-message" disabled/>
        <button className="btn-outline-secondary" type="button" id="shoutbox-button"><img alt={"Wyślij"} src={send}/></button>
      </div>
    }
    return <Container>
      {disclaimer && <Disclaimer>Dodając komentarz akceptujesz <Anchor onClick={() => {
        setDisclaimer(false);
        onRulesOpen();
      }}>Regulamin</Anchor> i <Anchor onClick={() => {
        setDisclaimer(false);
        onChatRulesOpen();
      }}>Zasady Chatu</Anchor></Disclaimer>}
      <div className="input-shoutbox">
        <input type="text" className="form-shoutbox" placeholder="Napisz swoją wiadomość..." id="shoutbox-message" autoComplete={"off"} onKeyDown={onEnterCheck}
               onFocus={() => setDisclaimer(true)}/>
        <button className="btn-outline-secondary" type="button" id="shoutbox-button" onClick={onSend}><img alt={"Wyślij"} src={send}/></button>
      </div>
    </Container>
  }

  return render();
}