import {ApiStreamer} from "#/src/definitions/ApiDefinitions";
import {Link} from "#/src/components/Link";
import {StreamerAvatar} from "#/src/components/streamer/StreamerAvatar";
import {StreamerGameIcon} from "#/src/components/streamer/StreamerGameIcon";
import {StreamerGameName} from "#/src/components/streamer/StreamerGameName";
import React from "react";

interface Props {
  streamer: ApiStreamer
  lazy?: boolean
}

export function StreamerNowLiveCard({streamer, lazy = false}: Props) {
  return <Link href={`/live/${streamer.slug}`} className="stream-user">
    <StreamerAvatar streamer={streamer}/>
    <div className="stream-content">
      <div className="stream-name">
        <div className="stream-link">{streamer.username}</div>
        <span className="badge badge-online">{streamer.viewers}</span>
      </div>
      <div className="stream-game">
        <StreamerGameIcon game={streamer.game} lazy={lazy}/>
        <StreamerGameName streamer={streamer} truncate={20}/>
      </div>
    </div>
    <div className="play-live">Oglądaj Live</div>
  </Link>
}