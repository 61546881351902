import {Swiper, SwiperSlide} from "swiper/react";
import {StreamerNowLiveCard} from "#/src/pages/index/StreamerNowLiveCard";
import React from "react";
import {useData} from "vike-react/useData";
import {ApiHomeData, ApiStreamer, ApiStreamerCategory} from "#/src/definitions/ApiDefinitions";
import {Sort} from "#/src/utils/sort-utils";
import {StreamerAvatar} from "#/src/components/streamer/StreamerAvatar";
import {StreamerGameIcon} from "#/src/components/streamer/StreamerGameIcon";
import {StreamerGameName} from "#/src/components/streamer/StreamerGameName";
import {DataWrapped} from "#/src/definitions/AppDefinitions";

export function NowLiveSection() {
  const {data: homeData} = useData() as DataWrapped<ApiHomeData>;
  const liveStreamers = homeData.navbarData.streamers.filter(s => s.isLive);

  function render() {
    return <section className="now-live">
      <div className="container-xxl">
        <div className="row">
          <div className="col-xl-12">
            <div className="title-flex mb-4">
              <div className="live-pulse green"></div>
              <h2 className="basic-headline">
                Teraz <span className="text-online">Live</span>
              </h2>
            </div>
          </div>
          <div className="col-xl-12">
            <a href={"/live"} className="link-page d-block mb-3">
              Ekipa <span className="text-primary">JaRock</span>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 15L12.5 10L7.5 5" stroke="#EEF5F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </a>
            <Swiper
              direction={"horizontal"}
              loop={true}
              grabCursor={true}
              slidesPerView={6}
              spaceBetween={30}
              breakpoints={{
                340: {slidesPerView: 1.5, spaceBetween: 20, centeredSlides: true},
                540: {slidesPerView: 2.15, spaceBetween: 30, centeredSlides: true},
                720: {slidesPerView: 2.15, spaceBetween: 30,},
                960: {slidesPerView: 3.2, spaceBetween: 30, centeredSlides: false},
                1140: {slidesPerView: 5, spaceBetween: 30, centeredSlides: false},
                1290: {slidesPerView: 6, spaceBetween: 30}
              }}
              className={"mb-4"}
            >
              {Sort.byStreamerDisplay(liveStreamers).filter(s => s.category.displayPriority === 1)
                .map(streamer => <SwiperSlide key={streamer.id} className={"live-card-sm"}>
                  <StreamerNowLiveCard key={streamer.id} streamer={streamer} lazy/>
                </SwiperSlide>)}
            </Swiper>
          </div>
        </div>
        <div className="row">{renderCategories()}</div>
      </div>
    </section>
  }

  function renderCategories() {
    return Sort.byDisplayPriority(homeData.navbarData.streamerCategories).map((category: ApiStreamerCategory) => {
      const streamers = Sort.byStreamerDisplay(homeData.navbarData.streamers.filter(s => s.category.id === category.id && s.isLive));
      if (streamers.length === 0) {
        return <React.Fragment key={category.id}></React.Fragment>
      }
      return <div key={category.id} className="col-xl-6 col-md-6">
        <a href={`/live?category=${category.name}`} className="nav-livetitle" aria-label={`Kategoria streamerów ${category.name}`}>
          {category.name}
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 15L12.5 10L7.5 5" stroke="#EEF5F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
        <ul className="nav-livelist">
          {streamers.map((streamer: ApiStreamer) => {
            return <li key={streamer.id} className="livelist-item">
              <a href={`/live/${streamer.slug}`} className="stretch-link" aria-label={`Livestream ${streamer.username}`}></a>
              <div className="stream-user">
                <StreamerAvatar streamer={streamer}/>
                <div className="stream-content">
                  <div className="stream-name">
                    <a href={`/live/${streamer.slug}`} className="stream-link">{streamer.username}</a>
                  </div>
                  <div className="stream-game">
                    <StreamerGameIcon game={streamer.game} lazy/>
                    <StreamerGameName streamer={streamer} truncate={25}/>
                  </div>
                </div>
              </div>
              <div className="livelist-info">
                <span className="badge badge-online">{streamer.viewers}</span>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 15L12.5 10L7.5 5" stroke="#EEF5F8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </li>
          })}
        </ul>
      </div>
    });
  }

  return render();
}