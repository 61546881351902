import React, {useState} from "react";
import {useData} from "vike-react/useData";
import {ApiArticle, ApiHomeData} from "#/src/definitions/ApiDefinitions";
import {Sort} from "#/src/utils/sort-utils";
import {ArticleCard} from "#/src/components/ArticleCard";
import {usePageContext} from "vike-react/usePageContext";
import {AppPageContext, DataWrapped, PaginatedRequest} from "#/src/definitions/AppDefinitions";
import {composeHeaders, composeQuery} from "#/src/utils/api-request-utils";
import AppConstants from "#/src/utils/app-constants";
import {LoadableButton} from "#/src/components/LoadableButton";
import styled from "@emotion/styled";
import {appendAt} from "#/src/utils/array-utils";

const LoadMoreButton = styled(LoadableButton)`
  min-width: 12em;
`;

export function OldArticlesSection() {
  const {data: homeData} = useData() as DataWrapped<ApiHomeData>;
  const pageContext = usePageContext() as AppPageContext;
  const oldArticles = Sort.byDate(homeData.oldArticles);
  const ad = homeData.adData
    .filter(a => a.placement == "BLOG_POST_PROMO")
    .filter(a => a.theme == (pageContext.user.lightMode ? "LIGHT" : "DARK"))[0];
  if(ad) {
    appendAt(oldArticles, 4, {
      id: ad.id,
      title: ad.description,
      description: ad.alt,
      slug: ad.campaignUrl,
      isAd: true
    });
  }
  const [articleData, setArticleData] = useState({error: false, data: oldArticles, page: 1, maxPages: 2});

  async function onPageFetch() {
    const nextPage = articleData.page + 1;
    return doFetchArticles(nextPage).then(res => {
      const newData = res.data;
      if(ad) {
        appendAt(newData, 4, {
          id: ad.id,
          title: ad.description,
          description: ad.alt,
          slug: ad.campaignUrl,
          isAd: true
        });
      }
      setArticleData({...articleData, page: nextPage});
      setArticleData({...articleData, data: [...articleData.data, ...Sort.byDate(newData)], page: res.page, maxPages: res.maxPages, error: false})
    }).catch(() => setArticleData({...articleData, error: true}));
  }

  async function doFetchArticles(page) {
    const query = composeQuery({page});
    const result = await fetch(`${AppConstants.API_ROUTE}/blog/history?${query}`, {method: "GET", headers: composeHeaders(pageContext)});
    if (result.ok) {
      const pageData = await result.json() as PaginatedRequest<ApiArticle>;
      return Promise.resolve(pageData);
    }
    return Promise.reject();
  }

  function render() {
    return <section className="article-latest">
      <div className="container-xxl">
        <div className="row">
          <div className="col-xl-12">
            <h2 className="basic-headline mb-7">Starsze publikacje</h2>
          </div>
        </div>
        <div className="row">{renderArticles()}</div>
        {renderFetchButton()}
      </div>
    </section>
  }

  function renderArticles() {
    if (articleData.error) {
      return <div>Błąd!</div>
    }
    return articleData.data.map((article: ApiArticle) => <div key={article.id} className="col-xl-3 col-sm-6 col-md-6"><ArticleCard article={article} lazy/></div>);
  }

  function renderFetchButton() {
    if (articleData.maxPages <= articleData.page) {
      return <></>
    }
    return <div className={"row justify-content-center"}>
      <div className={"text-center"}>
        <LoadMoreButton className={"btn btn-secondary"} onClick={onPageFetch}>Załaduj więcej</LoadMoreButton>
      </div>
    </div>
  }

  return render();
}